import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import reference_a_image from '../../image/home/reference/reference_a.jpg';
import reference_b_image from '../../image/home/reference/reference_b.jpg';


const Reference = () => {
    return (
        <>
            <Row className={"pt-5"}>
                <Col xs={{span: 6, offset: 3}}>
                    <h1 className={"text-center font-weight-bold"}>Skvelý výsledok a spokojnosť našich klientov</h1>
                </Col>
            </Row>
            <Row className={"py-2"}>
                <Col xs={12} lg={5} className={"bg-primary p-5"}>
                    <h3>
                        Objavte podlahové riešenia pre každého
                    </h3>
                    <hr/>
                    <p className={""}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                    </p>
                </Col>
                <Col xs={12} lg={7} className={""}>
                    <Image src={reference_a_image} className={"img-fluid"} height={100}/>
                </Col>
            </Row>
            <Row className={"py-2"}>
                <Col xs={12} lg={7} className={""}>
                    <Image src={reference_b_image} className={"img-fluid"} height={100}/>
                </Col>
                <Col xs={12} lg={5} className={"bg-dark p-5"}>
                    <h3 className={""}>
                        Radi sa popasujeme so stavebnými prácami
                    </h3>
                    <hr/>
                    <p className={""}>
                        Ponúkame spoľahlivosť a dlhoročné skusenosti
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Reference;
