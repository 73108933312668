import React from 'react';
import logo from './image/logo.png';
import phone from './image/phone.png';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Col, Container, Image, Nav, Navbar, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import HomePage from "./page/HomePage";
import {ErrorBoundary} from 'react-error-boundary'
import Error from "./component/Error";
import Pages from "./enum/pages";
import HomePageSubPages from "./enum/HomePageSubPages";

const App = () => {
    const navigate = useNavigate();
    return (
        <>
            {/*Menu background - placeholder*/}
            <div className="fixed-top app-menu bg-white"/>

            <div className="app-body">
                <ErrorBoundary
                    fallbackRender={({error, resetErrorBoundary}) => (
                        <Error/>
                    )}
                >
                    <div className="fixed-top">
                        <Container>
                            <Row className="app-menu py-1">
                                <Col xs={5} lg={4} className={'py-2 app-logo'}>
                                    <Image src={logo} className="" alt="logo"/>
                                </Col>
                                <Col xs={6} lg={0} className="app-menu-phone py-2 d-lg-none">
                                    <div className="">
                                        <Image src={phone} height={55}/><span className={'m-1'}>0903 977 159</span>
                                    </div>
                                </Col>
                                <Col xs={1} lg={4} className="app-menu-navigation">
                                    <Navbar expand='lg' variant="light" className={"justify-content-end"}>
                                        <Navbar.Toggle aria-controls='' className=''/>
                                        <Navbar.Collapse id='responsive-navbar-nav'>
                                            <Nav className="">
                                                <Nav.Item onClick={() => {
                                                    navigate(`${Pages.Home}`, {state: {at: new Date().getTime()}}
                                                    )
                                                }} className={"ml-auto"}>O&nbsp;nás</Nav.Item>
                                                <Nav.Item
                                                    onClick={() => {
                                                        navigate(`${Pages.Home}${HomePageSubPages.Service}`, {state: {at: new Date().getTime()}}
                                                        )
                                                    }
                                                    }
                                                    className={"ml-auto"}>Služby</Nav.Item>
                                                <Nav.Item
                                                    onClick={() => {
                                                        navigate(`${Pages.Home}${HomePageSubPages.Reference}`, {state: {at: new Date().getTime()}}
                                                        )
                                                    }}
                                                    className={"ml-auto"}>Referencie</Nav.Item>
                                                <Nav.Item
                                                    onClick={() => {
                                                        navigate(`${Pages.Home}${HomePageSubPages.ContactForm}`, {state: {at: new Date().getTime()}}
                                                        )
                                                    }}
                                                    className={"ml-auto"}>Kontakt</Nav.Item>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </Col>
                                <Col xs={0} lg={4} className="app-menu-phone py-2 d-none d-lg-block">
                                    <div className="right">
                                        <Image src={phone}/><span className={'m-3'}>0903 977 159</span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Routes>
                            <Route
                                path={`/${Pages.Home}`}
                                element={<HomePage/>}/>
                        </Routes>
                    </Container>
                </ErrorBoundary>
            </div>
        </>
    );
}

export default App;
