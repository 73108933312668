import React, {useEffect, useRef} from 'react';
import AboutUs from "../container/home/AboutUs";
import {useLocation} from "react-router-dom";
import Contact from "../container/home/Contact";
import ContactUsForm from "../container/home/ContactUsForm";
import HomePageSubPages from "../enum/HomePageSubPages";
import Service from "../container/home/Service";
import Reference from "../container/home/Reference";
import Pages from "../enum/pages";

interface HomePageProps {
}

const HomePage: React.FC<HomePageProps> = (props) => {
    const location = useLocation();
    const locationHash = location.hash;
    // @ts-ignore
    const locationAtTime = location?.state?.at;

    const aboutUsRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const servicesRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const referenceRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const contactUsRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const contactInfoRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const scrollToElement = (ref: React.RefObject<HTMLDivElement | null | undefined>) => {
        if (ref.current) {
            window.scrollTo({behavior: 'smooth', top: ref.current.offsetTop - 60})
        }
    }

    const scrollByUrlHash = (locationHash: any) => {
        if (locationHash === HomePageSubPages.AboutUs) {
            scrollToElement(aboutUsRef);
        } else if (locationHash === HomePageSubPages.Service) {
            scrollToElement(servicesRef);
        } else if (locationHash === HomePageSubPages.Reference) {
            scrollToElement(referenceRef);
        } else if (locationHash === HomePageSubPages.ContactForm) {
            scrollToElement(contactUsRef);
        } else if (locationHash === HomePageSubPages.ContactInfo) {
            scrollToElement(contactInfoRef);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            scrollByUrlHash(locationHash)
        }, 100)
    }, [locationHash, locationAtTime])

    useEffect(() => {

        const registerUpdateUrlOnElementVisibleEvent = (element: React.RefObject<HTMLDivElement>, url: HomePageSubPages) => {
            if (element.current) {
                const observer = new IntersectionObserver(([entry]) => {
                        if (entry.isIntersecting) {
                            if (
                                ((entry.rootBounds?.height ?? 0) < entry.boundingClientRect.height) ||
                                entry.intersectionRatio >= 0.7
                            ) {
                                window.history.pushState(url, '', `/#${Pages.Home}/${url}`);

                            }
                        }
                    }
                    , {threshold: [0.3, 0.7]});
                observer.observe(element.current);
            }
        }

        registerUpdateUrlOnElementVisibleEvent(aboutUsRef, HomePageSubPages.AboutUs);
        registerUpdateUrlOnElementVisibleEvent(servicesRef, HomePageSubPages.Service);
        registerUpdateUrlOnElementVisibleEvent(referenceRef, HomePageSubPages.Reference);
        registerUpdateUrlOnElementVisibleEvent(contactUsRef, HomePageSubPages.ContactForm);
        registerUpdateUrlOnElementVisibleEvent(contactInfoRef, HomePageSubPages.ContactInfo);
    }, [])

    return (
        <>
            <section ref={aboutUsRef} className={""}>
                <AboutUs/>
            </section>
            <section ref={servicesRef} className={""}>
                <Service/>
            </section>
            <section ref={referenceRef} className={""}>
                <Reference/>
            </section>
            <section ref={contactUsRef} className={"bg-secondary"}>
                <ContactUsForm/>
            </section>
            <section ref={contactInfoRef} className={""}>
                <Contact/>
            </section>
        </>
    );
}

export default HomePage;
