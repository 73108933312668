import React, {ChangeEvent, useCallback, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import validator from "validator";
import fetcher from "../../service/fetcher";
import Alert from "../../component/Alert";

interface Message {
    name: string,
    email: string,
    body: string,
}

interface FromPickerProps {
    message?: Message
    onChange: (message: Message) => void;
}

const FromPicker: React.FC<FromPickerProps> = (props) => {
    const onChange = (field: 'name' | 'email' | 'body', e: ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        let message: Message = {
            email: props.message?.email ?? '',
            name: props.message?.name ?? '',
            body: props.message?.body ?? ''
        };
        message[field] = value;
        props.onChange(message);
    }

    return <Row>
        <Col xs={12} className={"my-2"}>
            <input className={'p-3'} type="text" placeholder={"VAŠE MENO"} value={props.message?.name ?? ''}
                   onChange={(e) => {
                       onChange('name', e)
                   }}/>
        </Col>
        <Col xs={12} className={"my-2"}>
            <input className={'p-3'} type="email" placeholder={"VÁŠ EMAIL"} value={props.message?.email ?? ''}
                   onChange={(e) => {
                       onChange('email', e)
                   }}/>
        </Col>
        <Col xs={12} className={"my-2"}>
            <input className={'p-3'} type="text" placeholder={"VAŠA SPRÁVA"} value={props.message?.body ?? ''}
                   onChange={(e) => {
                       onChange('body', e)
                   }}/>
        </Col>
    </Row>;
}

const ContactDataPicker: React.FC = () => {

    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const [validationError, setValidationError] = useState<string | null>(null);
    const allowSend = (message?.email ?? '').length > 0 && (message?.body ?? '').length > 0;

    const showConfirmation = useCallback(() => {
        setConfirmation(true)
        setTimeout(() => {
            setConfirmation(false)
        }, 5000)
    }, [])

    return <Row className={"py-5"}>
        {validationError !== null && <Col xs={12}>
            <Alert title={'Správa nebola odoslaná'} bodyText={validationError} type={'error'}/>
        </Col>}
        {confirmation && validationError === null && <Col xs={12}>
            <Alert title={'Správa bola odoslaná'} bodyText={'Budeme Vás kontaktovať späť'} type={'info'}/>
        </Col>}
        <Col xs={{span: 6, offset: 3}}>
            <h1 className={"text-center"}>Krásne podlahy na prvý pohlad, ozvite sa nám!</h1>
        </Col>
        <Col xs={{span: 6, offset: 3}}>
            <FromPicker message={message} onChange={(message) => {
                setMessage(message);
            }}/>
        </Col>
        <Col xs={{span: 6, offset: 3}}>
            <Button variant={"primary"} disabled={!allowSend} onClick={async () => {
                if (message) {
                    const isEmailValid = validator.isEmail(message.email);
                    if (isEmailValid) {
                        const messageBody = message.body ?? '';
                        const isMessageValid = messageBody.length > 0;
                        if (isMessageValid) {
                            try {
                                await fetcher.post('/api/contact_form', {
                                    from: message.email,
                                    message: message.body,
                                    fromName: message.name
                                });
                                setValidationError(null);
                                setMessage(undefined);
                                showConfirmation()
                            } catch (e) {
                                console.error(e);
                                setValidationError('Správa nebol odoslaná!');
                            }
                        } else {
                            setValidationError("Správa je prázdna!");
                        }
                    } else {
                        setValidationError('Email odosielateľa nie je validný!');
                    }
                } else {
                    setValidationError('Správa je prázdna!');
                }
            }}>KONTAKTUJTE NÁS</Button>
        </Col>
    </Row>;
}
export default ContactDataPicker;