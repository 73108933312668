import React from "react";


interface AlertProps {
    type: 'error' | 'info' | 'warn',
    title: string,
    bodyText: string
}


const Alert: React.FC<AlertProps> = (props) => {

    let className = '';
    switch (props.type) {
        case 'error':
            className = 'bg-danger';
            break;
        case 'warn':
            className = 'bg-warning';
            break;
        case 'info':
            className = 'bg-info';
            break;
        default:
            className = 'bg-danger';
            break;
    }
    return <div className={`${className} p-5`}>
        <h3>{props.title}</h3>
        <p>{props.bodyText}</p>
    </div>;
}

export default Alert;