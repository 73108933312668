import axios from 'axios';
import getMetaTag from "../util/getMetaTag";


const apiUrl = process.env.REACT_APP_API_URL ?? '';

const get = async <T>(url: string) => {
    const response = await axios.get<T>(`${apiUrl}${url}`);
    const data: T = response.data;
    return data;
};

const post = async <T>(url: string, data: T) => {
    const _post = async (token: string) => {
        return await axios.post<T>(`${apiUrl}${url}`, data, {
            headers: {'xsrf-token': token}
        });
    }

    let token = getMetaTag('csrf');
    if (token !== null) {
        return await _post(token);
    } else {
        console.log('Csrf token not found');
        throw new Error('Csrf token not found');
    }

}

const getResponseBlob = async (url: string) => {
    const reponse = await axios.get(`${apiUrl}${url}`, {
        responseType: 'blob'  /* or responseType: 'arraybuffer'  */
    });
    return reponse.data;
};

const fetcher = {get, getResponseBlob, post};
export default fetcher;