import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import floor_image from '../../image/home/service/floor.jpg';
import building_image from '../../image/home/service/building.jpg';
import plasterboard_image from '../../image/home/service/plasterboard.jpg';
import wall_lining_image from '../../image/home/service/wall_lining.jpg';
import painting_image from '../../image/home/service/painting_image.jpg';


const Service = () => {
    return (
        <>
            <Row className={"py-5"}>
                <Col xs={{span: 6, offset: 3}}>
                    <h1 className={"text-center font-weight-bold"}>Naše služby</h1>
                    <p className={"text-center "}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.</p>
                </Col>
            </Row>
            <Row className={""}>
                <Col xs={12} lg={4} className={""}>
                    <Image src={floor_image} className={"img-fluid"} height={100}/>
                    <h5 className={"font-weight-bold p-4"}>
                        Pokládla podlahových krytín a liatych podláh
                    </h5>
                </Col>
                <Col xs={12} lg={4} className={""}>
                    <Image src={painting_image} className={"img-fluid"} height={100}/>
                    <h5 className={"font-weight-bold p-4"}>
                        Maľovanie interiéru a exteriéru
                    </h5>
                </Col>
                <Col xs={12} lg={4} className={""}>
                    <Image src={plasterboard_image} className={"img-fluid"} height={100}/>
                    <h5 className={"font-weight-bold p-4"}>
                        Sadrokartonárske práce
                    </h5>
                </Col>
                <Col xs={12} lg={{span: 4, offset: 2}} className={""}>
                    <Image src={building_image} className={"img-fluid"} height={100}/>
                    <h5 className={"font-weight-bold p-4"}>
                        Rekonštrukcia budov,
                        domov a bytov
                    </h5>
                </Col>
                <Col xs={12} lg={4} className={""}>
                    <Image src={wall_lining_image} className={"img-fluid"} height={100}/>
                    <h5 className={"font-weight-bold p-4"}>
                        Pokládla dlažieb a obkladov
                    </h5>
                </Col>

            </Row>
        </>
    );
}

export default Service;
