const getMetaTag = (name: string): string | null => {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === name) {
            let value = metas[i].getAttribute('content');
            return value ? value : null;
        }
    }
    return null;
}

export default getMetaTag;

