import React from 'react'
import {Col, Image, Row} from "react-bootstrap";
import logo from "../../image/logo.png";
import map from "../../image/home/contact/map.png";

function Contact() {
    return <Row className={"py-5"}>
        <Col xs={12} lg={4} className="text-center py-4">
            <Image src={logo} alt="logo" height={70} width={250}/>
        </Col>
        <Col xs={12} lg={4} className="py-4">
            <p className="text-center">Sme tu od toho, aby sme vás previedli vzrušujúcim procesom tvorby priestoru, na
                konci ktorého vznikne váš
                domov.</p>
        </Col>
        <Col xs={12} lg={4} className="text-center py-4">
            <div className="map_wrapper">
                <Image src={map} className="clickable" alt="map" height={150} width={290} onClick={() => {
                    window.open("https://maps.app.goo.gl/fVWesVXWNx1utpzK8");
                }}/>
                <div className={"address_in_map"}>
                    <div className={"company_name"}>Naša pobočka</div>
                    <div>Pribinova 13</div>
                    <div>Senec</div>
                </div>
            </div>
        </Col>
    </Row>
}

export default Contact;