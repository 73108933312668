import error_image from '../image/error.png';
import {Col, Image, Row} from "react-bootstrap";
import React from "react";

const Error = () => {
    return <Row className={"py-4"}>
        <Col xs={2}>
            <Image height={100} src={error_image} className={"img-fluid"}/>
        </Col>
        <Col xs={10} className={"p-4"}>
            <h3>Opps, nastala chyba. Je nám ľúto...</h3>
            <small>Na odstránení poruchy pracujeme.</small>
        </Col>
    </Row>
}

export default Error;